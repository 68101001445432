@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
  }
  body {
    @apply text-base;
  }
}
@layer utilities {
  .pt-safe {
    padding-top: env(safe-area-inset-top);
  }
  .pr-safe {
    padding-right: env(safe-area-inset-right);
  }
  .pb-safe {
    padding-bottom: env(safe-area-inset-bottom);
  }
  .pl-safe {
    padding-left: env(safe-area-inset-left);
  }
}
@layer components {
  .badge {
    @apply inline-flex items-center justify-center align-middle gap-2 py-0.5 px-3 font-medium text-sm text-gray-700 text-center bg-gray-300 rounded-2xl;
  }
  .badge-green {
    @apply text-green-700 bg-green-200;
  }
  .badge-blue {
    @apply text-blue-7600 bg-blue-200;
  }
  .badge-red {
    @apply text-red-700 bg-red-200;
  }
  .badge-yellow {
    @apply text-yellow-700 bg-yellow-200;
  }
}
@layer components {
  .button {
    @apply inline-flex items-center justify-center align-middle gap-3 h-10 py-2.5 px-4 font-medium text-sm text-gray-1100 text-center bg-transparent rounded-lg transition-colors select-none;
  }
  .button:hover {
    @apply bg-gray-200;
  }
  .button:focus {
    @apply outline-2 outline-gray-1100 outline-offset-4;
  }
  .button:active {
    @apply bg-gray-300;
  }
  .button[disabled], .button.disabled {
    @apply opacity-40 pointer-events-none;
  }
  .button-lg {
    @apply h-12 py-3.5;
  }
  .button-solid-green {
    @apply text-white bg-green-600;
  }
  .button-solid-green:hover {
    @apply bg-green-700;
  }
  .button-solid-green:active {
    @apply bg-green-800;
  }
  .button-solid-gray {
    @apply bg-gray-300;
  }
  .button-solid-gray:hover {
    @apply bg-gray-400;
  }
  .button-solid-gray:active {
    @apply bg-gray-500;
  }
  .button-outline {
    @apply border border-gray-400 shadow;
  }
}
.rte {
  font-size: 1em;
  line-height: 1.5em;
  color: #0D2034;
}
.rte *:first-child {
  margin-top: 0;
}
.rte h1, .rte h2, .rte h3, .rte h4, .rte h5, .rte h6 {
  margin-top: 2.5em;
  font-weight: 700;
}
.rte h2 {
  font-size: 1.5em;
  line-height: 2em;
}
.rte h3 {
  font-size: 1.25em;
  line-height: 1.75em;
}
.rte p, .rte ul, .rte ol {
  margin-top: 1em;
}
.rte ul {
  padding-left: 1.5em;
  list-style: disc;
}
.rte ol {
  padding-left: 1.5em;
  list-style: decimal;
}
.rte strong {
  font-weight: 700;
}
.rte .table:not(:first-child) {
  margin-top: 2.5em;
}
.rte table {
  width: 100%;
  table-layout: fixed;
  font-size: 0.875em;
}
.rte table td {
  padding: 0.5em 1em;
  border: 1px solid #DCDEE1;
}

@layer components {
  .form-check {
    @apply block w-5 h-5 bg-white border border-gray-400 cursor-pointer appearance-none transition;
  }
  input[type=checkbox].form-check {
    @apply rounded;
  }
  input[type=checkbox].form-check:indeterminate {
    @apply bg-green-600 bg-center bg-no-repeat border-green-600;
    background-size: 16px 16px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.3332 8H2.6665' stroke='%23FFFFFF' stroke-width='1.5'/%3E%3C/svg%3E");
  }
  input[type=radio].form-check {
    @apply rounded-full;
  }
  .form-check:hover {
    @apply border-gray-1100;
  }
  .form-check:focus {
    @apply outline-none ring-2 ring-gray-1100 ring-offset-2;
  }
  .form-check:checked {
    @apply bg-green-600 bg-center bg-no-repeat border-green-600;
  }
  input[type=checkbox].form-check:checked {
    background-size: 16px 16px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.3335 9.33331L5.66683 11.6666L12.6668 4.33331' stroke='%23FFFF' stroke-width='1.5'/%3E%3C/svg%3E");
  }
  input[type=radio].form-check:checked {
    background-size: 16px 16px;
    background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Ccircle fill='%23FFFFFF' cx='8' cy='8' r='4' /%3E%3C/svg%3E");
  }
  .form-check[disabled], .form-check.disabled {
    @apply opacity-40 pointer-events-none;
  }
  .form-check.invalid {
    @apply border-red-700;
  }
}
@layer components {
  .form-control {
    @apply block w-full h-12 p-3 text-gray-1100 placeholder-gray-700 bg-white ring-1 ring-gray-400 ring-inset rounded-lg shadow appearance-none transition-shadow;
  }
  .form-control.placeholder {
    @apply text-gray-700;
  }
  .form-control::-webkit-search-cancel-button {
    @apply appearance-none;
  }
  .form-control:hover {
    @apply ring-gray-500;
  }
  .form-control:focus {
    @apply outline-none ring-2 ring-gray-1100;
  }
  .form-control[readonly], .form-control.readonly {
    @apply pointer-events-none;
  }
  .form-control[disabled], .form-control.disabled {
    @apply opacity-40 pointer-events-none;
  }
  .form-control.invalid {
    @apply ring-red-700;
  }
  .form-control-icon {
    @apply text-gray-1100 transition-colors;
  }
  .form-control:placeholder-shown ~ .form-control-icon {
    @apply text-gray-700;
  }
}